<template>
  <div>
    <a-upload
        listType="picture-card"
        :fileList="fileList"
        @preview="handlePreview"
        @change="handleChange"
        :before-upload="beforeUpload"
    >
      <div v-if="fileList.length < 5">
        <a-icon type="plus" />
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>
    <a-modal
        :visible="previewVisible"
        :footer="null"
        @cancel="handleCancel"
    >
      <img v-if="previewImage !== ''" alt="example" style="width: 100%" :src="previewImage" />
      <video v-if="previewVideo !== ''" class="background-video" controls="controls" autoplay loop muted>
        <source :src="previewVideo" type="video/mp4">
        <source :src="previewVideo"  type="video/webm" />
        <source :src="previewVideo" type="video/ogg">
      </video>
    </a-modal>
  </div>
</template>

<script>
import {isFileImage, isFileVideo} from "@/helpers/file";
import axios from "axios";
import {getBearer} from "@/helpers/authHelper";

export default {
  props: ['draftTender', 'checkingInputs'],
  name: 'Upload',
  data () {
    return {
      previewVisible: false,
      previewImage: '',
      previewVideo: '',
      fileList: this.draftTender.files ? this.getImage(this.draftTender.files) : [],
      form: this.draftTender,
      checkingForm: this.checkingInputs,
      statusDetailText: 'success',
      helpDetailText: '',
      bordered: false,
      dateFormat: 'YYYY/MM/DD',
      i: 0,
      sizeArray: [],
      inputTextCityName: ''

      // locale: this.$i18n.locale,
    }
  },

  beforeMount() {
    this.fileList = this.draftTender.files ? this.getImage(this.draftTender.files) : []
  },

  methods: {
    getImage (imageNames) {
      let arr = []

      if (imageNames) {
        for (let value of imageNames) {
          const file = {
            type: 'image',
            uid: this.i,
            name: value,
            status: 'done',
            url: process.env.VUE_APP_SERVER_URL_FILE + value
          }

          arr.push(file)
          this.i += 1
        }
      }
      return arr
    },
    getVideo (videoNames) {
      let arr = []

      if (videoNames) {
        for (let value of videoNames) {
          const file = {
            type: 'video',
            uid: this.i,
            name: value,
            status: 'done',
            url: process.env.VUE_APP_SERVER_URL_FILE + value
          }

          arr.push(file)
          this.i += 1
        }
      }
      return arr
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      if (file.type === 'image') {
        this.previewVideo = ''
        this.previewImage = file.url || file.thumbUrl
      } else {
        this.previewImage = ''
        this.previewVideo = file.url || file.thumbUrl
      }
      this.previewVisible = true
    },
    handleChange ({ file, fileList }) {
      if (file.size === 2000000) {
        this.$notification.open({
          message: 'Файл больше разрешеного размера'
        })
        return
      }
      if (file.status === 'removed') {
        const newImages = this.form.files.filter((image) => {
          if (image !== file.name) {
            return image
          }
        })
        this.form.files = newImages
        this.fileList = fileList
        this.updateRemoveCommonSize(file)
      } else {
        if (this.getSummFiles() + file.size < 20000000) {
          this.submitFile(file)
        } else {
          this.$notification.open({
            message: 'Общий размер файлов больше чем возможно, удалите файл'
          })
        }
      }
    },
    beforeUpload () {
      return false
    },
    submitFile (file) {
      if (isFileImage(file.type)) {
        let formData = new FormData()
        formData.append('images[' + 0 + ']', file)
        axios
            .post(process.env.VUE_APP_SERVER_URL + '/images', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then((res) => {
              if (this.form.files) {
                this.form.files = [...this.form.files, res.data.data[0]]
                this.fileList = this.getImage(this.form.files)
              } else {
                this.form.files = [res.data.data[0]]
                this.fileList = this.getImage(this.form.files)
              }
              this.updateAddCommonSize(file, res.data.data[0])
            })
            .catch(function () {
              console.log('FAILURE!!')
            })
      } else if (isFileVideo(file.type)) {
        let formData = new FormData()
        formData.append('videos[' + 0 + ']', file)
        axios
            .post(process.env.VUE_APP_SERVER_URL + '/videos', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: getBearer()
              }
            })
            .then((res) => {
              if (this.form.files) {
                this.form.files = [...this.form.files, res.data.data[0]]
                this.fileList = this.getVideo(this.form.files)
              } else {
                this.form.files = [res.data.data[0]]
                this.fileList = this.getVideo(this.form.files)
              }
              this.updateAddCommonSize(file, res.data.data[0])
            })
            .catch(function () {
              console.log('FAILURE!!')
            })
      } else {
        this.$notification.open({
          message: 'Файл не поддерживаемого формата'
        })
      }
    },
    updateAddCommonSize (file, name) {
      this.sizeArray.push({
        name: name,
        size: file.size
      })
    },
    updateRemoveCommonSize (file) {
      const newCommonSize = this.sizeArray.filter((item) => item.name !== file.name)
      this.sizeArray = newCommonSize
    },
    getSummFiles () {
      let sum = 0
      for (let i of this.sizeArray) {
        sum += i.size
      }
      return sum
    },
    transferCreateTenderForm: function () {
      this.$emit('transferCreateTenderForm', this.form)
    },
    transferCheckingTenderForm () {
      this.$emit('transferCheckingTenderForm', this.form)
    },
    updateFilesTender (value) {
      if (this.form.files) {
        this.form.files = [...this.form.files, value]
      } else {
        this.form.files = [value]
      }
      this.checkingForm.statusName = 'success'
      this.checkingForm.helpName = ''
    },
  }
}
</script>

<style scoped>

</style>
