<template>
  <div class="main-master-wrapper">
    <Breadcrumb
      :breadcrumbs="breadcrumbs"
      mainLabelName="Создать тендер"
    ></Breadcrumb>
    <TabViewContainer>
      <div class="steps-container">
        <a-steps :current="current">
          <a-step v-for="item in steps" :key="item.title" :title="item.title">
            <template v-if="item.id < steps[current].id" v-slot:icon>
              <img src="@/assets/image/public-tender.svg" />
            </template>
          </a-step>
        </a-steps>
        <div class="steps-content">
          <CreateTenderTask
            v-bind:draftTender="draftTender"
            v-bind:checkingInputs="checkingInputs"
            v-if="current == 0"
          ></CreateTenderTask>
          <TenderDescription
            :isCreate="true"
            v-bind:draftTender="draftTender"
            v-if="current == 1"
            :subTags="this.$store.getters.getSubTags"
          ></TenderDescription>
          <CreateTenderPublic v-if="current == 2"></CreateTenderPublic>
        </div>
        <div class="steps-action">
          <a-button
            class="steps-previos-button"
            v-if="current > 0"
            @click="prev"
            style="height: 40px"
          >
            Назад
          </a-button>
          <a-button
            class="steps-next-button"
            v-if="current < steps.length - 1"
            @click="checkingForm"
             style="height: 40px"
          >
            Далее
          </a-button>
          <a-button
            class="steps-next-button"
            v-if="current == steps.length - 1"
            @click="transferToMainPage"
             style="height: 40px"
          >
            Готово
          </a-button>
        </div>
      </div>
    </TabViewContainer>
  </div>
</template>

<script>
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import CreateTenderTask from '@/components/Tender/CreateTenderTask.vue'
import TenderDescription from '@/components/Tender/TenderDescription.vue'
import CreateTenderPublic from '@/components/Tender/CreateTenderPublic.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import { getBearer } from '@/helpers/authHelper'
// import i18n from "@/i18n";

export default {
  mounted () {
    this.$store.dispatch('setSubTags')
    this.queryCity = this.$route.query.city
    this.queryTags = this.$route.query.tags ? [Number(this.$route.query.tags)] : []
    if (this.queryCity) {
      this.draftTender.address =this.queryCity
    }
    if (this.queryTags) {
      this.draftTender.subtagsId = this.queryTags
    }
  },
  components: {
    TabViewContainer,
    CreateTenderTask,
    CreateTenderPublic,
    Breadcrumb,
    TenderDescription
  },
  data () {
    return {
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        },
        {
          id: 2,
          name: 'Создать тендер',
          link: '/create_tender'
        }
      ],
      current: 0,
      steps: [
        { id: 1, title: 'Постановка задачи' },
        {
          id: 2,
          title: 'Превью'
        },
        {
          id: 3,
          title: 'Публикация тендера'
        }
      ],
      draftTender: {},
      checkingInputs: {
        statusSubtagsId: 'success',
        helpSubtagsId: '',
        statusName: 'success',
        helpName: '',
        statusTextDetail: 'success',
        helpTextDestail: '',
        statusStartMoney: 'success',
        helpStartMoney: '',
        statusEndMoney: 'success',
        helpEndMoney: '',
        statusDate: 'success',
        helpDate: '',
        statusCityName: 'success',
        helpCityName: ''
      },
      queryCity: '',
      queryTags: []
    }
  },

  methods: {
    transferToMainPage () {
      this.$router.push('/main')
    },
    checkingForm () {
      // console.log("this.$i18n", i18n.locale);

      if (!this.draftTender.subtagsId) {
        this.checkingInputs.statusSubtagsId = 'error'
        this.checkingInputs.helpSubtagsId =
          'Вы не выбрали ни одного направления'
      } else {
        if (this.draftTender.subtagsId.length === 0) {
          this.checkingInputs.statusSubtagsId = 'error'
          this.checkingInputs.helpSubtagsId =
            'Выберите хотя бы одно направление'
        }
      }
      if (!this.draftTender.address || this.draftTender.address === '') {
        this.checkingInputs.statusCityName = 'error'
        this.checkingInputs.helpCityName = 'Не выбран город работ'
      }
      if (!this.draftTender.detailText || this.draftTender.detailText === '') {
        this.checkingInputs.statusTextDetail = 'error'
        this.checkingInputs.helpTextDestail = 'Опишите задачу'
      }
      if (!this.draftTender.planned_start_price) {
        this.checkingInputs.statusStartMoney = 'error'
        this.checkingInputs.helpStartMoney = 'Не выбран денежный диапазон'
      }
      if (!this.draftTender.planned_end_price) {
        this.checkingInputs.statusEndMoney = 'error'
        this.checkingInputs.helpEndMoney = 'Не выбран денежный диапазон'
      }
      if (this.draftTender.planned_end_price &&
          this.draftTender.planned_start_price
          && Number(this.draftTender.planned_end_price) <  Number(this.draftTender.planned_start_price)) {
        this.checkingInputs.statusEndMoney = 'error'
        this.checkingInputs.helpEndMoney = 'Конечная сумма должна быть больше начальной'
      }
      if (!this.draftTender.name || this.draftTender.name === '') {
        this.checkingInputs.statusName = 'error'
        this.checkingInputs.helpName = 'Назовите ваш тендер'
      }
      if (
        !this.draftTender.planned_start_date &&
        !this.draftTender.planned_end_date
      ) {
        this.checkingInputs.statusDate = 'error'
        this.checkingInputs.helpDate = 'Не выбран срок работ'
      }

      if (
        this.checkingInputs.statusSubtagsId === 'success' &&
        this.checkingInputs.statusName === 'success' &&
        this.checkingInputs.statusTextDetail === 'success' &&
        this.checkingInputs.statusStartMoney === 'success' &&
        this.checkingInputs.statusEndMoney === 'success' &&
        this.checkingInputs.statusDate === 'success' &&
        this.checkingInputs.statusCityName === 'success'
      ) {
        this.next()
      }
    },
    next () {
      if (this.current === 1) {
        fetch(process.env.VUE_APP_SERVER_URL + '/tenders', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getBearer()
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(this.draftTender)
        })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            if (data.success) {
              this.$notification.open({
                message: 'Вы создали тендер!'
              })
            } else {
              this.$notification.open({
                message: 'Ошибка создания тендера!'
              })
            }
          })
      }
      this.current++
    },
    prev () {
      this.current--
    },
    createTenderForm (form) {
      this.draftTender = form
    },
    createCheckingTenderForm (checkingForm) {
      this.checkingInputs = checkingForm
    }
  }
}
</script>

<style>
.main-master-wrapper {
  background-color: white;
  width: 100%;
  height: 100%;
}

.steps-container {
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 19px 32px;
}

.steps-content {
  margin-top: 50px;
}

.ant-steps-item-active .ant-steps-item-icon {
  background-color: #b12021;
  border: none;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border: none;
}

.ant-steps-item-finish .ant-steps-finish-icon {
  color: #b12021;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #b12021;
}
.steps-action {
  display: flex;
  flex-direction: row;
}

.steps-previos-button {
  height: 42px;
  border: 1px solid #b12021;
  background-color: white;
  color: #b12021;
  margin-right: 14px;
  padding: 12px 26px;
  display: flex;
  align-items: center;
}

.steps-next-button {
  height: 42px;
  border: 1px solid #b12021;
  background-color: #b12021;
  color: white;
  padding: 12px 26px;
  display: flex;
  align-items: center;
}
</style>
