<template>
  <a-form :layout="'vertical'">
    <a-form-item
      :validateStatus="checkingInputs.statusSubtagsId"
      :help="checkingInputs.helpSubtagsId"
    >
      <div class="create-tender-label">Выберите тип тендера*</div>
      <a-select
        placeholder="Профиль работ"
        class="a-form-item-selec"
        size="large"
        @change="updateSubtagsId"
        showArrow
        mode="multiple"
        :default-value="getCurrentWorkTagsValue()"
      >
        <a-select-option v-for="i in getWorkTagsValue()" :key="i.id">
          {{ i.name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      :validateStatus="checkingInputs.statusName"
      :help="checkingInputs.helpName"
    >
      <div class="create-tender-label">Что требуется выполнить?*</div>
      <a-input
        class="a-form-item-input"
        v-model="form.name"
        v-on:input="updateNameTender($event)"
        placeholder="Название проекта"
      />
    </a-form-item>
    <a-form-item
      :validateStatus="checkingInputs.statusTextDetail"
      :help="checkingInputs.helpTextDestail"
    >
      <div class="create-tender-label">Расскажите о задаче подробнее*</div>
      <a-textarea
        :value="form.detailText"
        v-on:input="updateDetailText($event)"
        placeholder="Укажите объём и виды работ"
        :auto-size="{ minRows: 3, maxRows: 5 }"
        class="create__tender__text__area"
      />
    </a-form-item>
    <a-form-item>
      <div class="create-tender-label">Вложите файлы</div>
      <div>
        Если у вас есть готовое задание, фото или видео материалы, обязательно
        прикрепите их сюда. Исполнителм лучше оценят вашу задачу и сфрмулируют
        своё предложение.
      </div>
    </a-form-item>

    <a-form-item>
      <Upload
          v-bind:draftTender="draftTender"
          v-bind:checkingInputs="checkingInputs"
      ></Upload>
    </a-form-item>


<!--    <a-form-item>-->
<!--      <div class="clearfix">-->
<!--        <a-upload-->
<!--          listType="picture-card"-->
<!--          :fileList="fileList"-->
<!--          @preview="handlePreview"-->
<!--          @change="handleChange"-->
<!--          :before-upload="beforeUpload"-->
<!--        >-->
<!--          <div v-if="fileList.length < 5">-->
<!--            <a-icon type="plus" />-->
<!--            <div class="ant-upload-text">Upload</div>-->
<!--          </div>-->
<!--        </a-upload>-->
<!--        <a-modal-->
<!--          :visible="previewVisible"-->
<!--          :footer="null"-->
<!--          @cancel="handleCancel"-->
<!--        >-->
<!--          <img v-if="previewImage !== ''" alt="example" style="width: 100%" :src="previewImage" />-->
<!--          <video v-if="previewVideo !== ''" class="background-video" controls="controls" autoplay loop muted>-->
<!--            <source :src="previewVideo" type="video/mp4">-->
<!--            <source :src="previewVideo"  type="video/webm" />-->
<!--            <source :src="previewVideo" type="video/ogg">-->
<!--          </video>-->
<!--        </a-modal>-->
<!--      </div>-->
<!--    </a-form-item>-->

    <a-form-item
      :validateStatus="checkingInputs.statusCityName"
      :help="checkingInputs.helpCityName"
    >
      <div class="create-tender-label">Место проведения работ</div>
      <a-select
        show-search
        :placeholder="form.address"
        label-in-value
        option-filter-prop="children"
        size="large"
      >
        <a-select-option v-for="i in getCities()" :key="i.name" @click="updateAddress(i.name)">
          {{ i.name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item style="margin-bottom: 0px;">
      <div class="create-tender-label">Ориентировочный бюджет*</div>

      <a-form-item>
        <div class="create__tender__input__money__container">
          <div class="input-money-previe-text">от</div>
          <a-form-item
            :validateStatus="checkingInputs.statusStartMoney"
            :help="checkingInputs.helpStartMoney"
            class="create__tender__input__money__item"
          >
          <div class="input-money-containder">
            <a-input
              class="a-form-item-input"
              v-model="form.planned_start_price"
              v-on:input="updatePlannedStartPrice($event)"
              @keypress="validateNumber"
              size="large"
            >
              <template v-slot:suffix>RUB</template>
            </a-input>
          </div>
        </a-form-item>

        <span
          :style="{
            display: 'inline-block',
            width: '24px',
            textAlign: 'center',
          }"
        ></span>

          <div class="input-money-previe-text">до</div>
        <a-form-item
          :validateStatus="checkingInputs.statusEndMoney"
          :help="checkingInputs.helpEndMoney"
          class="create__tender__input__money__item"
        >
          <div class="input-money-containder">
            <a-input
              class="a-form-item-input"
              v-model="form.planned_end_price"
              v-on:input="updatePlannedEndPrice($event)"
              @keypress="validateNumber"
              size="large"
            >
              <template v-slot:suffix>RUB</template>
            </a-input>
          </div>
        </a-form-item>
        </div>
      </a-form-item>
    </a-form-item>
    <a-form-item
      :validateStatus="checkingInputs.statusDate"
      :help="checkingInputs.helpDate"
    >
      <div class="create-tender-label">Срок выполнения работ*</div>
      <a-range-picker
        :placeholder="['Начать работу', 'Завершить работу']"
        v-model="form.moment"
        @change="chooseDate"
        format="YYYY-MM-DD"
        :disabled-date="disabledDate"
        :defaultValue="[form.planned_start_date, form.planned_end_date]"
        size="large"
      />
      <!-- <button @click="show">show</button> -->
    </a-form-item>
  </a-form>
</template>

<script>

import Checkbox from 'ant-design-vue/lib/checkbox'
import Button from '@/components/common/Button.vue'
// import UploadFiles from "@/components/common/UploadFiles.vue";
import Upload from "@/ui-kit/Upload";
import axios from 'axios'
import {isFileImage, isFileVideo} from "@/helpers/file";
import {getBearer} from "@/helpers/authHelper";

export default {
  props: ['draftTender', 'checkingInputs'],
  components: {Button, Upload},
  mounted () {
    this.$store.dispatch('setTags', this.$store.getters.getTags)
    this.$store.dispatch('setCities', true)
  },

  data () {
    return {
      form: this.draftTender,
      checkingForm: this.checkingInputs,
      statusDetailText: 'success',
      helpDetailText: '',
      bordered: false,
      dateFormat: 'YYYY/MM/DD',
      inputTextCityName: ''
    }
  },

  computed: {
    cities () {
      const constCities = this.$store.getters.getCities
      if (this.inputTextCityName === '') {
        return constCities
      } else {
        const filterCities = this.$store.getters.getCities.filter(
          // el => el.toLowerCase().indexOf(this.inputTextCityName.toLowerCase()) !== -1
          (city) => city.name.includes(this.inputTextCityName)
          // console.log(city.name,this.inputTextCityName )
          // city.name.match(this.inputTextCityName)
        )
        return filterCities
      }
    }
  },

  methods: {
    getCities () {
      return this.$store.getters.getCities
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    validateNumber (e) {
      let keyCode = e.keyCode ? e.keyCode : e.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        e.preventDefault()
      }
    },
    disabledDate (current) {
      // Can not select days before today and today
      return current && current.valueOf() < Date.now()
    },
    getCurrentWorkTagsValue () {
      let arr = []
      if (this.draftTender.subtagsId) {
        this.draftTender.subtagsId.map((profile) => {
          arr.push(profile)
        })
      }
      return arr
    },
    chooseDate (value, dateString) {
      this.form.moment = value
      this.form.planned_start_date = dateString[0]
      this.form.planned_end_date = dateString[1]
      this.checkingForm.statusDate = 'success'
      this.checkingForm.helpDate = ''
    },

    transferCreateTenderForm: function () {
      this.$emit('transferCreateTenderForm', this.form)
    },
    transferCheckingTenderForm () {
      this.$emit('transferCheckingTenderForm', this.form)
    },
    getWorkTagsValue () {
      let arr = this.$store.getters.getSubTags
      return arr
    },
    updateFilesTender (value) {
      if (this.form.files) {
        this.form.files = [...this.form.files, value]
      } else {
        this.form.files = [value]
      }
      this.checkingForm.statusName = 'success'
      this.checkingForm.helpName = ''
    },
    updateNameTender (e) {
      this.form.name = e.target.value
      this.checkingForm.statusName = 'success'
      this.checkingForm.helpName = ''
    },
    updateDetailText (e) {
      this.form.detailText = e.target.value
      this.checkingForm.statusTextDetail = 'success'
      this.checkingForm.helpTextDestail = ''
    },
    updateSubtagsId (value) {
      this.form.subtagsId = value
      this.checkingForm.statusSubtagsId = 'success'
      this.checkingForm.helpSubtagsId = ''
    },

    updatePlannedStartPrice (e) {
      this.form.planned_start_price = e.target.value
      this.checkingForm.statusStartMoney = 'success'
      this.checkingForm.helpStartMoney = ''
    },
    updatePlannedEndPrice (e) {
      this.form.planned_end_price = e.target.value
      this.checkingForm.statusEndMoney = 'success'
      this.checkingForm.helpEndMoney = ''
    },
    updateAddress (value) {
      this.form.address = value
      this.checkingForm.statusCityName = 'success'
      this.checkingForm.helpCityName = ''
    },

    toushCityNameFromArray (name) {
      this.inputTextCityName = name
      this.checkingForm.statusCityName = 'success'
      this.checkingForm.helpCityName = ''
    },

    inputNameCity (value) {
      this.inputTextCityName = value
      this.statusCityName = 'success'
      this.helpCityName = ''
      this.placeholder = 'Ведите название города'
    },

    filterArray () {
      const constCities = this.$store.getters.getCities.map((city) => city.name)
      return constCities
      // if (this.inputTextCityName == "") {
      //   console.log("constCities", constCities);
      //   return constCities;
      // } else {
      //   const filterCities = this.$store.getters.getCities.filter((city) =>
      //     city.name.match(this.inputTextCityName)
      //   );
      //   console.log("filterCities", filterCities);
      //   return filterCities;
      // }
    }
  }
}
</script>

<style lang="scss">
.ant-select-selection-placeholder {
  color: black;
}
.create__tender__input__money {
  &__container{
    display: flex;
    flex-direction: row;
  }
  &__item{
    //display: 'inline-block';
    width: calc(50% - 12px);
  }
}

.input-money-containder {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.input-money-previe-text {
  margin-right: 12px;
  height: 40px;
  display: flex;
  align-items: center;
}
.unBordered-input {
  border: none;
}
.create__tender__text__area {
  resize: none;
}

/* .upload-container {
  height: 40px;
  width: 100%;
  border: 1px solid #b12021;
  color: #b12021;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
} */

.upload-project {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.upload-text {
  color: #b12021;
}

.ant-upload {
  width: 100%;
  height: 100%;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.upload-image {
  margin-right: 11px;
}

.create-tender-label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: black;
  margin-bottom: 17px;
}

.a-form-item-input {
  height: 40px;
}

@media (max-width: 720px) {
.create__tender__input__money {
  &__container{
    flex-direction: column;
    margin-bottom: 0px;
  }
  &__item{
    //display: 'inline-block';
    width: 80%;
  }
}
}
</style>
